import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isAuth, logout } from '../../actions/auth';
import setAuthToken from '../../utils/setAuthToken';
import PropTypes from 'prop-types';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const Navbar = ({ isAuth, isAuthenticated, logout, user }) => {
  useEffect(() => {
    if (!isAuthenticated) {
      isAuth();
    }
  }, [isAuth, isAuthenticated]);
  const hist = useHistory();

  return (
    <nav className='navbar navbar-expand-lg fixed-top navbar-dark bg-main py-3'>
      <div className='mx-5 w-100 d-flex align-items-center justify-content-between '>
        <Link className='navbar-brand' to='/'>
          {
            <img
              src={process.env.PUBLIC_URL + `/images/logo.png`}
              alt=''
              height={40}
            />
          }
        </Link>
        {isAuthenticated && (
          <div
            className='cursor-pointer d-flex '
            onClick={() => {
              logout().then(() => {
                hist.push('/');
                console.log(1);
              });
            }}
          >
            {user !== null && <b>{user.FirstName + ' ' + user.LastName}</b>}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              enable-background='new 0 0 24 24'
              height='24px'
              viewBox='0 0 24 24'
              className='mx-2'
              width='24px'
              fill='#ffffff'
            >
              <g>
                <path d='M0,0h24v24H0V0z' fill='none' />
              </g>
              <g>
                <g>
                  <path d='M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z' />
                  <path d='M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z' />
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  isAuth: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { isAuth, logout })(Navbar);
