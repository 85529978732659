import React, { useMemo } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

function ProgressBarStatus({
  rawScore,
  status,
  calculationType,
  wellBelowBenchmarkRange,
  belowBenchmarkRange,
  atBenchmarkRange,
  aboveBenchmarkRange,
}) {
  function getLowerboundFromRange(range) {
    return range.split(" to ")[0];
  }

  function getUpperboundFromRange(range) {
    return range.split(" to ")[1];
  }

  function convertValueToPercentage(value, max) {
    return (value / max) * 100;
  }

  /**
   * Calculates the benchmark progress for each portion based on the provided ranges.
   *
   * @returns {Object} An object containing the calculated benchmark progress portion on pill bar.
   */
  const calculateBenchmarkProgress = useMemo(() => {
    const max = parseInt(getUpperboundFromRange(aboveBenchmarkRange));
    const wellBelowUpperBound = parseInt(getUpperboundFromRange(wellBelowBenchmarkRange));
    const belowUpperBound = parseInt(getUpperboundFromRange(belowBenchmarkRange));
    const atUpperBound = parseInt(getUpperboundFromRange(atBenchmarkRange));

    let data = {};

    // if max is on 100% scale. Calculate the portion of each range
    if (max === 100) {
      data.wellBelowBenchmarkPortion = wellBelowUpperBound;
      data.belowBenchmarkPortion = belowUpperBound - wellBelowUpperBound;
      data.atBenchmarkPortion = atUpperBound - belowUpperBound;
      data.aboveBenchmarkPortion = max - atUpperBound;
    }
    else{
      data.wellBelowBenchmarkPortion = convertValueToPercentage(wellBelowUpperBound, max);
      data.belowBenchmarkPortion = convertValueToPercentage(belowUpperBound, max) - data.wellBelowBenchmarkPortion;
      data.atBenchmarkPortion = convertValueToPercentage(atUpperBound, max) - convertValueToPercentage(belowUpperBound, max);
      data.aboveBenchmarkPortion = 100 - convertValueToPercentage(atUpperBound, max);
    }

    return data;
  }, [
    wellBelowBenchmarkRange,
    belowBenchmarkRange,
    atBenchmarkRange,
    aboveBenchmarkRange,
  ]);

  /**
   * Calculates the position of the indicator based on the raw score and above benchmark range.
   *
   * @param {number} rawScore - The raw score.
   * @param {number} aboveBenchmarkRange - The above benchmark range.
   * @returns {number} The position of the indicator.
   */
  const calculateIndicatorPosition = useMemo(() => {
    const max = getUpperboundFromRange(aboveBenchmarkRange);

    const indicatorPosition = convertValueToPercentage(rawScore, max);

    return indicatorPosition;
  }, [rawScore, aboveBenchmarkRange]);

  // Percentile Pillbar
  if (calculationType === 1) {
    return (
      <>
        {/* Indicator:
          - Raw score is displayed on the indicator
          - If raw score is present, display an arrow pointing downwards
      */}
        <div
          className="w-100 d-flex flex-column align-items-center justify-content-center position-relative"
          style={{ minHeight: "4rem" }}
        >
          <div
            className="position-absolute top-0"
            style={{
              zIndex: 1,
              marginTop: "-0.5rem",
              left: `max(0px, calc(${calculateIndicatorPosition}% - 0.5rem))`, // Subtract 0.5rem to center the indicator eg. 99% will be at the edge of the container + the size of the indicator. Use max to ensure the indicator does not below 0.
              boxSizing: "border-box", // Include padding and border in element's total width and height
            }}
          >
            {rawScore === 0 ? "" : rawScore}
            {rawScore && (
              <div className="position-absolute top-50">
                <span>&darr;</span>
              </div>
            )}
          </div>

          {/* 
              Custom progress bar:
              - Divide the progress bar into 4 sections based on the status
              - Show corresponding color for each section based on the status
            */}
          <div className="custom-progress-bar position-relative">
            <ProgressBar>
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "Well Below" ? "#fc6868" : "white"
                  }`,
                  borderRight: "1px solid black",
                }}
                now={9}
                key={4}
              />
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "Below" ? "#decf3f" : "white"
                  }`,
                  borderRight: "1px solid black",
                }}
                now={19}
                key={3}
              />
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "At Grade" ? "#60bd68" : "white"
                  }`,
                  borderRight: "1px solid black",
                }}
                now={89}
                key={2}
              />
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "Exceeding" ? "#4ab5eb" : "white"
                  }`,
                }}
                now={100}
                key={1}
              />
            </ProgressBar>
          </div>
        </div>
      </>
    );
  }
  // Benchmark Pillbar
  else {
    return (
      <>
        {/* Indicator:
          - Raw score is displayed on the indicator
          - If raw score is present, display an arrow pointing downwards
      */}
        <div
          className="w-100 d-flex flex-column align-items-center justify-content-center position-relative"
          style={{ minHeight: "4rem" }}
        >
          <div
            className="position-absolute top-0"
            style={{
              zIndex: 1,
              marginTop: "-0.5rem",
              left: `max(0px, calc(${calculateIndicatorPosition}% - 0.5rem))`, // Subtract 0.5rem to center the indicator eg. 99% will be at the edge of the container + the size of the indicator. Use max to ensure the indicator does not below 0.

              boxSizing: "border-box", // Include padding and border in element's total width and height
            }}
          >
            {rawScore === 0 ? "" : rawScore}
            {rawScore && (
              <div className="position-absolute top-50">
                <span>&darr;</span>
              </div>
            )}
          </div>

          {/* 
              Custom progress bar:
              - Divide the progress bar into 4 sections based on the status
              - Show corresponding color for each section based on the status
            */}
          <div className="w-100 custom-progress-bar">
            <ProgressBar>
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "Well Below Benchmark" ? "#fc6868" : "white"
                  }`,
                  borderRight: "1px solid black",
                }}
                now={calculateBenchmarkProgress.wellBelowBenchmarkPortion}
                key={4}
              />
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "Below Benchmark" ? "#decf3f" : "white"
                  }`,
                  borderRight: "1px solid black",
                }}
                now={calculateBenchmarkProgress.belowBenchmarkPortion} 
                key={3}
              />
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "At Benchmark" ? "#60bd68" : "white"
                  }`,
                  borderRight: "1px solid black",
                }}
                now={calculateBenchmarkProgress.atBenchmarkPortion} 
                key={2}
              />
              <ProgressBar
                style={{
                  backgroundColor: `${
                    status === "Above Benchmark" ? "#5AC8FA" : "white"
                  }`,
                }}
                now={calculateBenchmarkProgress.aboveBenchmarkPortion} 
                key={1}
              />
            </ProgressBar>
          </div>
        </div>
      </>
    );
  }
}

export default ProgressBarStatus;
