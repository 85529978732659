import React, { useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  LabelList,
  ReferenceLine,
} from "recharts";

const AverageRawScoreByBenchmarkCategory = ({ calculationType, chartData }) => {
  const [bars, setBars] = useState([]);

  useEffect(() => {
    // Set the stacked bars based on the calculation type
    if (chartData) {
      console.log("chartData", chartData);
      if (calculationType) {
        setBars([
          { dataKey: "Well Below", fill: "#fc6868" },
          { dataKey: "Below", fill: "#decf3f" },
          { dataKey: "At Grade", fill: "#60bd68" },
          { dataKey: "Exceeding", fill: "#4ab5eb" },
        ]);
      } else {
        setBars([
          { dataKey: "Well Below Benchmark", fill: "#fc6868" },
          { dataKey: "Below Benchmark", fill: "#decf3f" },
          { dataKey: "At Benchmark", fill: "#60bd68" },
          { dataKey: "Above Benchmark", fill: "#4ab5eb" },
        ]);
      }
    }
  }, [chartData]);

  // Format the data to have gap between bechmarks
  const formatData = (calculationType, data) => {
    let dataStructure = [];
    let formattedData;
    // Set data structure for different calculation system
    if (calculationType) {
      dataStructure = [
        {
          id: "gap0",
          BenchMark: null,
          Category: null,
          AveragePercentile: null,
        },
        {
          id: "Fall - Break up the Word",
          BenchMark: "Fall",
          Category: "Break up the Word",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Fall - Letter Sounds",
          BenchMark: "Fall",
          Category: "Letter Sounds",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Fall - Name the Letter",
          BenchMark: "Fall",
          Category: "Name the Letter",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Fall - Say the first sound",
          BenchMark: "Fall",
          Category: "Say the first sound",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Fall - Silly Word(CLS)",
          BenchMark: "Fall",
          Category: "Silly Word(CLS)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Fall - Silly Word(WWR)",
          BenchMark: "Fall",
          Category: "Silly Word(WWR)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Fall - Story Reading(%)",
          BenchMark: "Fall",
          Category: "Story Reading(%)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Fall - Story Reading(TWC)",
          BenchMark: "Fall",
          Category: "Story Reading(TWC)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "gap1",
          BenchMark: null,
          Category: null,
          AveragePercentile: null,
        },
        {
          id: "Winter - Break up the Word",
          BenchMark: "Winter",
          Category: "Break up the Word",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Winter - Letter Sounds",
          BenchMark: "Winter",
          Category: "Letter Sounds",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Winter - Name the Letter",
          BenchMark: "Winter",
          Category: "Name the Letter",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Winter - Say the first sound",
          BenchMark: "Winter",
          Category: "Say the first sound",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Winter - Silly Word(CLS)",
          BenchMark: "Winter",
          Category: "Silly Word(CLS)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Winter - Silly Word(WWR)",
          BenchMark: "Winter",
          Category: "Silly Word(WWR)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Winter - Story Reading(%)",
          BenchMark: "Winter",
          Category: "Story Reading(%)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Winter - Story Reading(TWC)",
          BenchMark: "Winter",
          Category: "Story Reading(TWC)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "gap2",
          BenchMark: null,
          Category: null,
          AveragePercentile: null,
        },
        {
          id: "Spring - Break up the Word",
          BenchMark: "Spring",
          Category: "Break up the Word",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Spring - Letter Sounds",
          BenchMark: "Spring",
          Category: "Letter Sounds",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Spring - Name the Letter",
          BenchMark: "Spring",
          Category: "Name the Letter",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Spring - Say the first sound",
          BenchMark: "Spring",
          Category: "Say the first sound",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Spring - Silly Word(CLS)",
          BenchMark: "Spring",
          Category: "Silly Word(CLS)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Spring - Silly Word(WWR)",
          BenchMark: "Spring",
          Category: "Silly Word(WWR)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Spring - Story Reading(%)",
          BenchMark: "Spring",
          Category: "Story Reading(%)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "Spring - Story Reading(TWC)",
          BenchMark: "Spring",
          Category: "Story Reading(TWC)",
          AveragePercentile: 0,
          "Well Below": 9,
          Below: 10,
          "At Grade": 70,
          Exceeding: 11,
        },
        {
          id: "gap3",
          BenchMark: null,
          Category: null,
          AveragePercentile: null,
        },
      ];
      formattedData = data?.reduce((acc, d, index) => {
        const existingItem = acc.find((item) => item.id === d.id);
        if (!existingItem) {
          acc.push(d);
        } else {
          existingItem.AveragePercentile = d.AveragePercentile;
        }
        return acc;
      }, dataStructure);
    } else {
      dataStructure = [
        {
          id: "gap0",
          BenchMark: null,
          Category: null,
          AverageRawScore: null,
        },
        {
          id: "Fall - Break up the Word",
          BenchMark: "Fall",
          Category: "Break up the Word",
          AverageRawScore: 0,
        },
        {
          id: "Fall - Letter Sounds",
          BenchMark: "Fall",
          Category: "Letter Sounds",
          AverageRawScore: 0,
        },
        {
          id: "Fall - Name the Letter",
          BenchMark: "Fall",
          Category: "Name the Letter",
          AverageRawScore: 0,
        },
        {
          id: "Fall - Say the first sound",
          BenchMark: "Fall",
          Category: "Say the first sound",
          AverageRawScore: 0,
        },
        {
          id: "Fall - Silly Word(CLS)",
          BenchMark: "Fall",
          Category: "Silly Word(CLS)",
          AverageRawScore: 0,
        },
        {
          id: "Fall - Silly Word(WWR)",
          BenchMark: "Fall",
          Category: "Silly Word(WWR)",
          AverageRawScore: 0,
        },
        {
          id: "Fall - Story Reading(%)",
          BenchMark: "Fall",
          Category: "Story Reading(%)",
          AverageRawScore: 0,
        },
        {
          id: "Fall - Story Reading(TWC)",
          BenchMark: "Fall",
          Category: "Story Reading(TWC)",
          AverageRawScore: 0,
        },
        {
          id: "gap1",
          BenchMark: null,
          Category: null,
          AverageRawScore: null,
        },
        {
          id: "Winter - Break up the Word",
          BenchMark: "Winter",
          Category: "Break up the Word",
          AverageRawScore: 0,
        },
        {
          id: "Winter - Letter Sounds",
          BenchMark: "Winter",
          Category: "Letter Sounds",
          AverageRawScore: 0,
        },
        {
          id: "Winter - Name the Letter",
          BenchMark: "Winter",
          Category: "Name the Letter",
          AverageRawScore: 0,
        },
        {
          id: "Winter - Say the first sound",
          BenchMark: "Winter",
          Category: "Say the first sound",
          AverageRawScore: 0,
        },
        {
          id: "Winter - Silly Word(CLS)",
          BenchMark: "Winter",
          Category: "Silly Word(CLS)",
          AverageRawScore: 0,
        },
        {
          id: "Winter - Silly Word(WWR)",
          BenchMark: "Winter",
          Category: "Silly Word(WWR)",
          AverageRawScore: 0,
        },
        {
          id: "Winter - Story Reading(%)",
          BenchMark: "Winter",
          Category: "Story Reading(%)",
          AverageRawScore: 0,
        },
        {
          id: "Winter - Story Reading(TWC)",
          BenchMark: "Winter",
          Category: "Story Reading(TWC)",
          AverageRawScore: 0,
        },
        {
          id: "gap2",
          BenchMark: null,
          Category: null,
          AverageRawScore: null,
        },
        {
          id: "Spring - Break up the Word",
          BenchMark: "Spring",
          Category: "Break up the Word",
          AverageRawScore: 0,
        },
        {
          id: "Spring - Letter Sounds",
          BenchMark: "Spring",
          Category: "Letter Sounds",
          AverageRawScore: 0,
        },
        {
          id: "Spring - Name the Letter",
          BenchMark: "Spring",
          Category: "Name the Letter",
          AverageRawScore: 0,
        },
        {
          id: "Spring - Say the first sound",
          BenchMark: "Spring",
          Category: "Say the first sound",
          AverageRawScore: 0,
        },
        {
          id: "Spring - Silly Word(CLS)",
          BenchMark: "Spring",
          Category: "Silly Word(CLS)",
          AverageRawScore: 0,
        },
        {
          id: "Spring - Silly Word(WWR)",
          BenchMark: "Spring",
          Category: "Silly Word(WWR)",
          AverageRawScore: 0,
        },
        {
          id: "Spring - Story Reading(%)",
          BenchMark: "Spring",
          Category: "Story Reading(%)",
          AverageRawScore: 0,
        },
        {
          id: "Spring - Story Reading(TWC)",
          BenchMark: "Spring",
          Category: "Story Reading(TWC)",
          AverageRawScore: 0,
        },
        {
          id: "gap3",
          BenchMark: null,
          Category: null,
          AverageRawScore: null,
        },
      ];

      formattedData = data?.reduce((acc, d, index) => {
        const existingItem = acc.find((item) => item.id === d.id);
        if (!existingItem) {
          acc.push(d);
        } else {
          existingItem.AverageRawScore = d.AverageRawScore;
          // 4- Well Below Benchmark, 3- Below Benchmark, 2- At Benchmark, 1- Above Benchmark
          existingItem.fill =
            d.riskStatus === 4
              ? "#fc6868"
              : d.riskStatus === 3
              ? "#decf3f"
              : d.riskStatus === 2
              ? "#60bd68"
              : "#4ab5eb";
        }
        return acc;
      }, dataStructure);
    }

    return formattedData;
  };

  // Custom x-axis tick
  const customXaxisTick = (tickProps, type) => {
    const { x, y, payload, index } = tickProps;
    const { value } = payload;

    const benchmarkName = value.split(" - ")[0];
    const categoryName = value.split(" - ")[1];

    // Check if the xAxisId is "Benchmark" to distinguish between the two X-axes
    if (type === "benchmark") {
      // Only display labels on Say first word category benchmark
      if (categoryName === "Say the first sound") {
        return (
          <>
            <text x={x} y={y + 100} textAnchor="middle" fontWeight="bold">
              {benchmarkName}
            </text>
          </>
        );
      } else {
        return null;
      }
    } else {
      return (
        <text
          x={x}
          y={y}
          textAnchor="end"
          transform={`rotate(-90, ${x}, ${y})`}
          fontSize={11}
        >
          {categoryName}
        </text>
      );
    }
  };

  
  const customLabellist = (props) => {
    const { x, y, width, height, value, fill } = props;
    const MIN_HEIGHT = 20; // Adjust this value as needed
  
    const isBarSmall = height < MIN_HEIGHT;
    const verticalPosition = isBarSmall ? y - 5 : y + height / 2; // Adjust these values as needed
  
    return (
      <text
        x={x + width / 2}
        y={verticalPosition}
        fill={height < MIN_HEIGHT ? "black" : "white"}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value?.toFixed(0)}
      </text>
    );
  };
  // Customized label
  // const customLabellist = (props) => {
  //   const { x, y, width, height, value, fill } = props;

  //   // Calculate the y position based on the value
  //   const labelY = value > 0 ? y + height / 2 : y;

  //   return (
  //     <text
  //       x={x + width / 2}
  //       y={labelY}
  //       fill={fill}
  //       textAnchor="middle"
  //       dominantBaseline={value > 0 ? "middle" : "baseline"}
  //     >
  //       {value?.toFixed(0)}
  //     </text>
  //   );
  // };

  return (
    <div className="card shadow-lg border-0  py-5 px-4 mb-2 flex-fill">
      <h6>
        Progression of Student By Category Type -{" "}
        {calculationType ? "Percentile" : "Benchmark Status"}
      </h6>
      <div className="d-flex flex-column">
        <div className="">
          <div
            className="w-100 d-flex justify-content-start"
            style={{ fontSize: "0.7rem" }}
          >
            <b className="">Result</b>
          </div>

          {calculationType ? (
            <>
              <ResponsiveContainer minHeight={400}>
                <BarChart
                  layout="horizontal"
                  data={formatData(calculationType, chartData)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 30,
                    bottom: 150,
                  }}
                  barGap={0} // Adjust the gap between bars within the same category based on testData length
                  barCategoryGap={10}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="id"
                    group="category"
                    tick={(props) => customXaxisTick(props, "category")}
                    interval={0}
                  />
                  <XAxis
                    dataKey="id"
                    group="benchmark"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    tick={(props) => customXaxisTick(props, "benchmark")}
                    height={1}
                    xAxisId="Benchmark"
                  />
                  <YAxis type="number" />

                  {/* Customize progression indicator bar */}
                  <>
                    {bars?.map((bar, index) => (
                      <Bar
                        key={index}
                        dataKey={bar.dataKey}
                        fill={bar.fill}
                        stackId="Category"
                        barSize={50}
                        xAxisId="two"
                      ></Bar>
                    ))}
                    <XAxis xAxisId="two" hide />

                    {/*Percentile Indicator bar*/}
                    <Bar
                      dataKey={"AveragePercentile"}
                      fill="rgba(0, 0, 0, 0.2)"
                      barSize={50}
                    >
                      <LabelList
                        dataKey={"AveragePercentile"}
                        fill="white"
                        content={customLabellist}
                      />
                    </Bar>
                  </>

                  <XAxis xAxis="one" />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: "30px" }}
                  />

                  {/* Reference lines 
                Only show reference lines for percentile calculation
              */}

                  <>
                    <ReferenceLine
                      y={20}
                      stroke="green"
                      strokeWidth={2}
                      strokeDasharray="3 3"
                      label={{
                        value: "At Grade(Min): 20",
                        position: "insideBottomLeft",
                        fill: "black",
                        background: "black",
                        fontSize: 12,
                      }}
                    />
                    <ReferenceLine
                      y={89}
                      stroke="green"
                      strokeWidth={2}
                      strokeDasharray="3 3"
                      label={{
                        value: "At Grade(Max): 89",
                        position: "insideTopLeft",
                        fill: "black",
                        background: "black",
                        fontSize: 12,
                      }}
                    />
                  </>
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <>
              <ResponsiveContainer minHeight={400}>
                <BarChart
                  layout="horizontal"
                  data={formatData(calculationType, chartData)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 30,
                    bottom: 150,
                  }}
                  barGap={0} // Adjust the gap between bars within the same category based on testData length
                  barCategoryGap={10}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="id"
                    group="category"
                    tick={(props) => customXaxisTick(props, "category")}
                    interval={0}
                  />
                  <XAxis
                    dataKey="id"
                    group="benchmark"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    tick={(props) => customXaxisTick(props, "benchmark")}
                    height={1}
                    xAxisId="Benchmark"
                  />
                  <YAxis type="number" />

                  {/* Customize progression indicator bar */}
                  <>
                    {/*Percentile Indicator bar*/}
                    <Bar dataKey={"AverageRawScore"} barSize={50}>
                      <LabelList
                        dataKey={"AverageRawScore"}
                        fill="white"
                        content={customLabellist}
                      />
                    </Bar>
                  </>

                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: "30px" }}
                    content={() => (
                      <div className="w-100 d-flex justify-content-center gap-4 mx-auto">
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#fc6868",
                            }}
                          ></div>
                          <div style={{ color: "#fc6868" }}>
                            Well Below Benchmark
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#decf3f",
                            }}
                          ></div>
                          <div style={{ color: "#decf3f" }}>Below Benchmark</div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#60bd68",
                            }}
                          ></div>
                          <div style={{ color: "#60bd68" }}>At Benchmark</div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#4ab5eb",
                            }}
                          ></div>
                          <div style={{ color: "#4ab5eb" }}>
                            Above Benchmark
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </BarChart>
              </ResponsiveContainer>
            </>
          )}

          <div
            className="w-100 d-flex justify-content-end"
            style={{ fontSize: "0.7rem" }}
          >
            <b className="">{calculationType?'Percentile':null}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AverageRawScoreByBenchmarkCategory;
