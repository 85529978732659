import React from 'react';

/**
 * Pagination Component
 * @param {number} totalItems - The total number of items to paginate
 * @param {number} itemsPerPage - Number of items per page
 * @param {number} currentPage - The current active page
 * @param {function} onPageChange - Callback function to handle page change
 * @param {number} maxPagesToShow - Maximum number of pages to display in the pagination bar (optional, default is 10)
 */
const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange, maxPagesToShow = 10 }) => {
  // Calculate total number of pages needed
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // If there is only one page, no need to display pagination
  if (totalPages <= 1) return null;

  // Array to hold the page numbers to be displayed
  const pages = [];
  // Determine the starting page number
  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  // Determine the ending page number
  let endPage = Math.min(totalPages, currentPage + Math.floor(maxPagesToShow / 2));

  // Adjust start and end pages if the number of pages displayed is less than maxPagesToShow
  if (endPage - startPage + 1 < maxPagesToShow) {
    if (startPage === 1) {
      // If start page is 1, set end page to max possible based on maxPagesToShow
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    } else {
      // Otherwise, adjust start page accordingly
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
  }

  // Populate the pages array with page numbers from startPage to endPage
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <li className="page-item" onClick={() => onPageChange(i)} key={i}>
        <div className={`page-link txt-primary ${i === currentPage ? "page-selected" : ""}`}>
          {i}
        </div>
      </li>
    );
  }

  // Render the pagination component
  return (
    <nav aria-label="Page navigation txt-primary">
      <ul className="pagination txt-primary">
        {/* Previous button */}
        {currentPage > 1 && (
          <li className="page-item" onClick={() => onPageChange(currentPage - 1)}>
            <div className="page-link txt-primary" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </div>
          </li>
        )}
        {/* Pages */}
        {pages}
        {/* Next button */}
        {currentPage < totalPages && (
          <li className="page-item" onClick={() => onPageChange(currentPage + 1)}>
            <div className="page-link txt-primary" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
