import React, { useState, useEffect } from "react";
import Search from "../../layouts/Search";
import _ from "lodash";
import ClassroomStudentFilter from "./ClassroomStudentFilter";
import SortIcon from "../../layouts/SortIcon";
import ProgressBarStatus from "../../layouts/PercentileBar";

const ClassroomStudentGrid = ({ reports, reportsLoading }) => {
  const formatReports = (arr) => {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].RawScore!==undefined &&
        (!arr[i].wellBelowBenchmarkRange.includes("N/A") ||
          !arr[i].belowBenchmarkRange.includes("N/A") ||
          !arr[i].atBenchmarkRange.includes("N/A") ||
          !arr[i].aboveBenchmarkRange.includes("N/A"))
      ) {
        result.push(arr[i]);
      }
    }
    return result;
  };

  const [listSearch, setListSearch] = useState(formatReports(reports));

  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    Year: "",
    BenchMark: "",
    Category: "",
    Grade: "",
    Percentile: "",
    BenchmarkStatus: "",
  });

  const [sortKeys, setSortKeys] = useState([]);

  useEffect(() => {
    if (sortKeys.length > 0) {
      setListSearch(
        _.orderBy(
          searchInput(filterInput(formatReports(reports), formData), search),
          getKeys(sortKeys),
          getOrder(sortKeys)
        )
      );
    } else {
      setListSearch(
        searchInput(filterInput(formatReports(reports), formData), search)
      );
    }
  }, [sortKeys]);

  useEffect(() => {
    if (!reportsLoading) {
      setListSearch(formatReports(reports));
    }
  }, [reportsLoading, setListSearch, reports]);

  const [icons, setIcons] = useState({
    name: 0,
    year: 0,
    benchmark: 0,
    category: 0,
    title: 0,
    grade: 0,
    percentile: 0,
    teacher: 0,
    score: 0,
    risk: 0,
    section: 0,
    wellBelow: 0,
    below: 0,
    atBenchmark: 0,
    aboveBenchmark: 0,
  });

  const {
    name,
    year,
    benchmark,
    category,
    title,
    grade,
    percentile,
    teacher,
    score,
    risk,
    section,
    wellBelow,
    below,
    atBenchmark,
    aboveBenchmark,
  } = icons;

  const getKeys = (arr) => {
    return arr.map((a) => a.key);
  };
  const getOrder = (arr) => {
    return arr.map((a) => a.order);
  };

  const setFilter = (data) => {
    setIcons({
      name: 0,
      year: 0,
      benchmark: 0,
      category: 0,
      title: 0,
      grade: 0,
      percentile: 0,
      teacher: 0,
      score: 0,
      risk: 0,
      section: 0,
      wellBelow: 0,
      below: 0,
      atBenchmark: 0,
      aboveBenchmark: 0,
    });
    setListSearch(filterInput(listSearch, data));
  };

  const searchInput = (arr, word) => {
    console.log("Test search input", arr, word);
    return arr.filter(
      (item) =>
        item?.StudentName.toUpperCase().includes(word.toUpperCase()) ||
        item?.Year.toString().toUpperCase().includes(word.toUpperCase()) ||
        item?.BenchMark.toUpperCase().includes(word.toUpperCase()) ||
        item?.Category.toUpperCase().includes(word.toUpperCase()) ||
        item?.Grade.toUpperCase().includes(word.toUpperCase()) ||
        item?.Title.toUpperCase().includes(word.toUpperCase()) ||
        item?.PercentileRank.toString()
          .toUpperCase()
          .includes(word.toUpperCase())
    );
  };

  const filterInput = (arr, data) => {
    let result = arr?.filter(
      (item) =>
        (data.Year === ""
          ? true
          : item.Year.toString()
              ?.toUpperCase()
              .includes(data.Year.toString()?.toUpperCase())) &&
        (data.BenchMark === ""
          ? true
          : item.BenchMark?.toUpperCase() === data.BenchMark?.toUpperCase()) &&
        (data.Category === ""
          ? true
          : item.Category?.toUpperCase() === data.Category?.toUpperCase()) &&
        (data.Grade === ""
          ? true
          : item.Grade?.toUpperCase() === data.Grade?.toUpperCase()) &&
        (data.Percentile === ""
          ? true
          : item.PercentileRank?.toUpperCase() ===
            data.Percentile?.toUpperCase()) &&
        (data.BenchmarkStatus === ""
          ? true
          : item.riskStatus?.toUpperCase() ===
            data.BenchmarkStatus?.toUpperCase())
    );

    console.log("test filter result", result);

    return result;
  };

  return (
    <>
      {!reportsLoading ? (
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="txt-primary">Student Results</div>
            <div className="d-flex">
              <Search
                setListSearch={setListSearch}
                setSearch={setSearch}
                filter={(e) => {
                  setIcons({
                    name: 0,
                    year: 0,
                    benchmark: 0,
                    category: 0,
                    title: 0,
                    grade: 0,
                    percentile: 0,
                    teacher: 0,
                    score: 0,
                    section: 0,
                    risk: 0,
                  });
                  return searchInput(
                    filterInput(formatReports(reports), formData),
                    e.target.value
                  );
                }}
              />

              <ClassroomStudentFilter
                setFilter={setFilter}
                setListSearch={setListSearch}
                formatReports={formatReports}
                reports={reports}
                formData={formData}
                setFormData={setFormData}
                searchInput={searchInput}
                search={search}
              />
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (name === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "StudentName", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        name: 1,
                      });
                    } else if (name === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "StudentName")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        name: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "StudentName")
                      );
                      setIcons({
                        ...icons,
                        name: 0,
                      });
                    }
                  }}
                >
                  Student Name
                  <SortIcon icon={name} />
                </th>
                {/* Field: Year */}
                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (year === 0) {
                      setSortKeys([...sortKeys, { key: "Year", order: "asc" }]);
                      setIcons({
                        ...icons,
                        year: 1,
                      });
                    } else if (year === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "Year") return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        year: -1,
                      });
                    } else {
                      setSortKeys(sortKeys.filter((a) => a.key !== "Year"));
                      setIcons({
                        ...icons,
                        year: 0,
                      });
                    }
                  }}
                >
                  Year
                  <SortIcon icon={year} />
                </th>
                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (benchmark === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "BenchMark", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        benchmark: 1,
                      });
                    } else if (benchmark === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "BenchMark")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        benchmark: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "BenchMark")
                      );
                      setIcons({
                        ...icons,
                        benchmark: 0,
                      });
                    }
                  }}
                >
                  Benchmark
                  <SortIcon icon={benchmark} />
                </th>
                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (category === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "Category", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        category: 1,
                      });
                    } else if (category === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "Category")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        category: -1,
                      });
                    } else {
                      setSortKeys(sortKeys.filter((a) => a.key !== "Category"));
                      setIcons({
                        ...icons,
                        category: 0,
                      });
                    }
                  }}
                >
                  Category
                  <SortIcon icon={category} />
                </th>
                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (title === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "Title", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        title: 1,
                      });
                    } else if (title === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "Title") return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        title: -1,
                      });
                    } else {
                      setSortKeys(sortKeys.filter((a) => a.key !== "Title"));
                      setIcons({
                        ...icons,
                        title: 0,
                      });
                    }
                  }}
                >
                  Title
                  <SortIcon icon={title} />
                </th>
                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (grade === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "Grade", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        grade: 1,
                      });
                    } else if (grade === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "Grade") return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        grade: -1,
                      });
                    } else {
                      setSortKeys(sortKeys.filter((a) => a.key !== "Grade"));
                      setIcons({
                        ...icons,
                        grade: 0,
                      });
                    }
                  }}
                >
                  Grade
                  <SortIcon icon={grade} />
                </th>
                {/* Field Raw Score */}
                <th
                  width="10%"
                  scope="col"
                  onClick={() => {
                    if (score === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "RawScore", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        score: 1,
                      });
                    } else if (score === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "RawScore")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        score: -1,
                      });
                    } else {
                      setSortKeys(sortKeys.filter((a) => a.key !== "RawScore"));
                      setIcons({
                        ...icons,
                        score: 0,
                      });
                    }
                  }}
                >
                  Score
                  <SortIcon icon={score} />
                </th>
                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (percentile === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "Percentile", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        percentile: 1,
                      });
                    } else if (percentile === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "Percentile")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        percentile: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "Percentile")
                      );
                      setIcons({
                        ...icons,
                        percentile: 0,
                      });
                    }
                  }}
                >
                  Percentile
                  <SortIcon icon={percentile} />
                </th>
                {/* Field: Risk Status */}
                <th
                  width="10%"
                  scope="col"
                  onClick={() => {
                    if (risk === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "riskStatus", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        risk: 1,
                      });
                    } else if (risk === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "riskStatus")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        risk: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "riskStatus")
                      );
                      setIcons({
                        ...icons,
                        risk: 0,
                      });
                    }
                  }}
                >
                  Benchmark Status
                  <SortIcon icon={risk} />
                </th>
                {/* Field Section */}
                <th
                  className="text-center"
                  width="10%"
                  scope="col"
                  onClick={() => {
                    if (section === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "Section", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        section: 1,
                      });
                    } else if (section === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "Section")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        section: -1,
                      });
                    } else {
                      setSortKeys(sortKeys.filter((a) => a.key !== "Section"));
                      setIcons({
                        ...icons,
                        section: 0,
                      });
                    }
                  }}
                >
                  Section
                  <SortIcon icon={section} />
                </th>

                <th
                  scope="col"
                  width="10%"
                  onClick={() => {
                    if (teacher === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "TeacherName", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        teacher: 1,
                      });
                    } else if (teacher === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "TeacherName")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        teacher: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "TeacherName")
                      );
                      setIcons({
                        ...icons,
                        teacher: 0,
                      });
                    }
                  }}
                >
                  Teacher
                  <SortIcon icon={teacher} />
                </th>
                {/* Field: Well Below Benchmark Range */}
                <th
                  width="10%"
                  scope="col"
                  onClick={() => {
                    if (wellBelow === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "wellBelowBenchmarkRange", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        wellBelow: 1,
                      });
                    } else if (wellBelow === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "wellBelowBenchmarkRange")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        wellBelow: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter(
                          (a) => a.key !== "wellBelowBenchmarkRange"
                        )
                      );
                      setIcons({
                        ...icons,
                        wellBelow: 0,
                      });
                    }
                  }}
                >
                  Well Below Benchmark
                  <SortIcon icon={wellBelow} />
                </th>
                {/* Field: Below Benchmark Range */}
                <th
                  width="10%"
                  scope="col"
                  onClick={() => {
                    if (below === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "belowBenchmarkRange", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        below: 1,
                      });
                    } else if (below === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "belowBenchmarkRange")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        below: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "belowBenchmarkRange")
                      );
                      setIcons({
                        ...icons,
                        below: 0,
                      });
                    }
                  }}
                >
                  Below Benchmark
                  <SortIcon icon={below} />
                </th>
                {/* Field: at Benchmark Range */}
                <th
                  width="10%"
                  scope="col"
                  onClick={() => {
                    if (atBenchmark === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "atBenchmarkRange", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        atBenchmark: 1,
                      });
                    } else if (atBenchmark === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "atBenchmarkRange")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        atBenchmark: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "atBenchmarkRange")
                      );
                      setIcons({
                        ...icons,
                        atBenchmark: 0,
                      });
                    }
                  }}
                >
                  At Benchmark
                  <SortIcon icon={atBenchmark} />
                </th>
                {/* Field: Above Benchmark Range */}
                <th
                  width="10%"
                  scope="col"
                  onClick={() => {
                    if (aboveBenchmark === 0) {
                      setSortKeys([
                        ...sortKeys,
                        { key: "aboveBenchmarkRange", order: "asc" },
                      ]);
                      setIcons({
                        ...icons,
                        aboveBenchmark: 1,
                      });
                    } else if (aboveBenchmark === 1) {
                      setSortKeys(
                        sortKeys.map((a) => {
                          if (a.key === "aboveBenchmarkRange")
                            return { ...a, order: "desc" };
                          return a;
                        })
                      );
                      setIcons({
                        ...icons,
                        aboveBenchmark: -1,
                      });
                    } else {
                      setSortKeys(
                        sortKeys.filter((a) => a.key !== "aboveBenchmarkRange")
                      );
                      setIcons({
                        ...icons,
                        aboveBenchmark: 0,
                      });
                    }
                  }}
                >
                  Above Benchmark
                  <SortIcon icon={aboveBenchmark} />
                </th>
              </tr>
            </thead>
            <tbody>
              {listSearch.length &&
                listSearch
                  ?.filter((item) => item.Percentile !== null)
                  ?.map((item, id) => {
                    return (
                      <tr key={id}>
                        <td>{item.StudentName}</td>
                        <td>{item.Year}</td>
                        <td>{item.BenchMark}</td>
                        <td>{item.Category}</td>
                        <td>{item.Title}</td>
                        <td>{item.Grade}</td>
                        <td>{item?.RawScore}</td>
                        <td>{item.Percentile}</td>
                        <td>
                          <ProgressBarStatus
                            calculationType={2}
                            rawScore={item?.RawScore}
                            status={item?.riskStatus}
                            wellBelowBenchmarkRange={
                              item?.wellBelowBenchmarkRange
                            }
                            belowBenchmarkRange={item?.belowBenchmarkRange}
                            atBenchmarkRange={item?.atBenchmarkRange}
                            aboveBenchmarkRange={item?.aboveBenchmarkRange}
                          />
                        </td>
                        <td className="text-center">{item.Section}</td>
                        <td>{item.TeacherName}</td>
                        <td>{item.wellBelowBenchmarkRange}</td>
                        <td>{item.belowBenchmarkRange}</td>
                        <td>{item.atBenchmarkRange}</td>
                        <td>{item.aboveBenchmarkRange}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="spinner-border txt-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default ClassroomStudentGrid;
