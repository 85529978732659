// User actions
export const AUTH_SUCCESS = "REGISTER_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const AUTH = "AUTH";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGIN = "LOGIN";

// Admin actions
export const GET_USERSLIST = "GET_USERSLIST";
export const GET_USER = "GET_USER";
export const CLEAR_USER = "CLEAR_USER";

export const GET_SCHOOLSLIST = "GET_SCHOOLSLIST";
export const GET_SCHOOL = "GET_SCHOOL";
export const CLEAR_SCHOOL = "CLEAR_SCHOOL";

export const GET_ASSESSMENTSLIST = "GET_ASSESSMENTSLIST";
export const GET_ASSESSMENT = "GET_ASSESSMENT";
export const CLEAR_ASSESSMENT = "CLEAR_ASSESSMENT";

export const GET_ASSESSMENTSINSTRUCTIONLIST = "GET_ASSESSMENTS_INSTRUCTIONLIST";
export const GET_ASSESSMENTINSTRUCTION = "GET_ASSESSMENT_INSTRUCTION";
export const CLEAR_ASSESSMENTINSTRUCTION = "CLEAR_ASSESSMENT_INSTRUCTION";

export const GET_CLASSROOMSLIST = "GET_CLASSROOMSLIST";
export const GET_CLASSROOM = "GET_CLASSROOM";
export const CLEAR_CLASSROOM = "CLEAR_CLASSROOM";

export const GET_RESULTS = "GET_RESULTS";
export const GET_RESULT = "GET_RESULT";
export const CLEAR_RESULT = "CLEAR_RESULT";

export const GET_REPORT = "GET_REPORT";
export const CLEAR_REPORT = "CLEAR_REPORT";

export const GET_COMPARE_REPORT = "GET_COMPARE_REPORT";
export const CLEAR_COMPARE_REPORT = "CLEAR_COMPARE_REPORT";

export const CLEAR_ADMIN = "CLEAR_ADMIN";
export const CLEAR_ITEMS = "CLEAR_ITEMS";

// Assessment actions
export const GET_ASSESSMENT_RESULTS = "GET_ASSESSMENT_RESULTS";
export const ADD_ASSESSMENT_RESULTS = "ADD_ASSESSMENT_RESULTS";
export const CLEAR_ASSESSMENT_RESULTS = "CLEAR_ASSESSMENT_RESULTS";

// Chart actions
export const GET_OVERALL_RESULTS_CHART = "GET_OVERALL_RESULTS_CHART";
export const CLEAR_OVERALL_RESULTS_CHART = "CLEAR_OVERALL_RESULTS_CHART";
export const GET_OVERALL_RESULTS_BY_ASSESSMENT_CHART =
  "GET_OVERALL_RESULTS_BY_ASSESSMENT_CHART";
export const CLEAR_OVERALL_RESULTS_BY_ASSESSMENT_CHART =
  "CLEAR_OVERALL_RESULTS_BY_ASSESSMENT_CHART";
export const GET_OVERALL_RESULTS_PROGRESSION_CHART =
  "GET_OVERALL_RESULTS_PROGRESSION_CHART";
export const CLEAR_OVERALL_RESULTS_PROGRESSION_CHART =
  "CLEAR_OVERALL_RESULTS_PROGRESSION_CHART";
export const GET_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART =
  "GET_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART";
export const CLEAR_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART =
  "CLEAR_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART";

export const GET_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART2 =
  "GET_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART2";
export const CLEAR_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART2 =
  "CLEAR_AVERAGE_SCORE_BENCHMARK_CATEGORY_CHART2";

// Get a range of results
export const GET_OVERALL_RESULTS_CHARTS = "GET_OVERALL_RESULTS_CHARTS";
export const CLEAR_OVERALL_RESULTS_CHARTS = "CLEAR_OVERALL_RESULTS_CHARTS";
export const GET_OVERALL_RESULTS_BY_ASSESSMENT_CHARTS =
  "GET_OVERALL_RESULTS_BY_ASSESSMENT_CHARTS";
export const CLEAR_OVERALL_RESULTS_BY_ASSESSMENT_CHARTS =
  "CLEAR_OVERALL_RESULTS_BY_ASSESSMENT_CHARTS";
export const GET_OVERALL_RESULTS_PROGRESSION_CHARTS =
  "GET_OVERALL_RESULTS_PROGRESSION_CHARTS";
export const CLEAR_OVERALL_RESULTS_PROGRESSION_CHARTS =
  "CLEAR_OVERALL_RESULTS_PROGRESSION_CHARTS";

// Alerts actions
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
